import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export enum TagType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  grey = 'grey',
  aqua = 'aqua',
  purple = 'purple',
  purpleDark = 'purple-dark',
  pink = 'pink',
  apple = 'apple',
  brown = 'brown',
  green = 'green',
  blue = 'blue',
}

@Component({
  selector: 'rkb-tag',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input()
  dot: boolean = false;

  @HostBinding('class')
  @Input()
  type: TagType = TagType.success;
}
