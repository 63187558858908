import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Brand, BrandApiItem, convertBrandApiToBrand, convertToApiBrandItem } from '../models/brand.model';
import { Encoding, EncodingApiItem } from '../models/encoding.model';
import {
  convertFileSourceApiReponse,
  convertStreamSourceApiItem,
  convertStreamSourceApiReponse,
  convertToFormStreamSourceApiItem,
  FileSource,
  FileSourceApiItem,
  FormStreamSource,
  StreamSource,
} from '../models/source.model';
import { StreamSourceApiItem } from './../models/source.model';

const BRAND_URLS = {
  brand: (orgId: string, id: string) =>
    `${environment.urls.RK_BUSINESS_API}/organizations/${orgId}/brands/${id}`,
  brandEncodings: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${id}/encodings`,
  brandSourcesStreams: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${id}/sources/streams`,
  brandSourcesFiles: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${id}/sources/files`,
  brandTags: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${id}/programs/tags`,
  secretKey: (id: string, sourceId: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${id}/sources/streams/${sourceId}/secret_key`,
};

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  constructor(private readonly http: HttpClient) {}

  getBrand(orgId: string, id: string): Observable<Brand> {
    return this.http
      .get<BrandApiItem>(BRAND_URLS.brand(orgId, id))
      .pipe(map(convertBrandApiToBrand));
  }

  getBrandEncodings(id: string): Observable<EncodingApiItem[]> {
    return this.http.get<EncodingApiItem[]>(BRAND_URLS.brandEncodings(id));
  }

  createBrandEncoding(
    id: string,
    encoding: Partial<Encoding>,
  ): Observable<EncodingApiItem> {
    return this.http.post<EncodingApiItem>(BRAND_URLS.brandEncodings(id), encoding);
  }

  getBrandSources(
    id: string,
  ): Observable<{ streams: StreamSource[]; files: FileSource[] }> {
    return combineLatest([
      this.http.get<StreamSourceApiItem[]>(BRAND_URLS.brandSourcesStreams(id)),
      this.http.get<FileSourceApiItem[]>(BRAND_URLS.brandSourcesFiles(id)),
    ]).pipe(
      map(([streams, files]) => {
        return {
          streams: convertStreamSourceApiReponse(streams),
          files: convertFileSourceApiReponse(files),
        };
      }),
    );
  }

  postBrandStreamSource(
    id: string,
    source: FormStreamSource,
    parent?: StreamSource,
  ): Observable<StreamSource[]> {
    return this.http
      .post<StreamSourceApiItem>(
        BRAND_URLS.brandSourcesStreams(id),
        convertToFormStreamSourceApiItem(source),
      )
      .pipe(
        map(newSourceApi => {
          const newSource = convertStreamSourceApiItem(newSourceApi);
          return parent ? [parent, newSource] : [newSource];
        }),
      );
  }

  updateBrand(orgId: string, id: string, brand: Partial<Brand>): Observable<Brand> {
    return this.http
      .put<BrandApiItem>(BRAND_URLS.brand(orgId, id), convertToApiBrandItem(brand))
      .pipe(map(convertBrandApiToBrand));
  }

  getTags(id: string): Observable<string[]> {
    return this.http.get<string[]>(BRAND_URLS.brandTags(id));
  }

  regenerateSecretKey(id: string, source: StreamSource) {
    return this.http.patch<{secret_key: string}>(BRAND_URLS.secretKey(id, source.id), null).pipe(
      map(data => {
        const newSource: StreamSource = { ...source, secretKey: data.secret_key };
        return newSource;
      }),
    );
  }
}
