export interface Encoding {
  id: string;
  name: string;
  codec: string;
  bitrate: number;
  samplerate: number;
  channels: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface EncodingApiItem {
  id: string;
  name: string;
  Codec: string;
  Bitrate: number;
  Samplerate: number;
  Channels: string;
  created_at: string;
  updated_at: string;
}
export function convertEncodingApiResponse(response: EncodingApiItem[]): Encoding[] {
  return response.map(item => ({
    id: item.id,
    name: item.name,
    codec: item.Codec,
    bitrate: item.Bitrate,
    samplerate: item.Samplerate,
    channels: item.Channels,
    createdAt: new Date(item.created_at),
    updatedAt: new Date(item.updated_at),
  }));
}

