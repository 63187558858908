import moment from 'moment';

export type Codec = 'mp3' | 'aac';
export interface StreamSource {
  id: string;
  name: string;
  host: string;
  port: string[];
  mount: string;
  codec: Codec;
  allowedIPs: string[];
  secretKey: string;
  streamingKey: string;
  failoverSource: StreamSource;
  parentID?: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
}

export interface StreamSourceApiItem {
  id: string;
  name: string;
  allowed_ips: string[];
  host: string;
  port: string[];
  mount: string;
  codec: Codec;
  failover_source: StreamSourceApiItem;
  secret_key: string;
  streaming_key: string;
  parent_id?: string;
  created_at: string;
  updated_at: string;
}

export function convertStreamSourceApiReponse(
  sources: StreamSourceApiItem[],
): StreamSource[] {
  return sources.map(convertStreamSourceApiItem);
}

export function convertStreamSourceApiItem(source: StreamSourceApiItem): StreamSource {
  return {
    id: source.id,
    name: source.name,
    host: source.host,
    port: source.port,
    mount: source.mount,
    codec: source.codec,
    allowedIPs: source.allowed_ips,
    failoverSource:
      source.failover_source && convertStreamSourceApiItem(source.failover_source),
    secretKey: source.secret_key,
    streamingKey: source.streaming_key,
    parentID: source.parent_id ?? null,
    createdAt: moment(source.created_at),
    updatedAt: moment(source.updated_at),
  };
}

export interface FormStreamSourceApiItem {
  name: string;
  allowed_ips: string[];
  parent_id?: string;
}

export interface FormStreamSource {
  name: string;
  allowedIPs: string[];
  parentID?: string;
}

export function convertToFormStreamSourceApiItem(
  source: FormStreamSource,
): FormStreamSourceApiItem {
  return {
    name: source.name,
    allowed_ips: source.allowedIPs,
    parent_id: source?.parentID,
  };
}

export interface FileSource {
  id: string;
  name: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  codec: Codec;
  duration: number;
  filesize: number;
}

export interface FileSourceApiItem {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  codec: Codec;
  duration: number;
  filesize: number;
}

export function convertFileSourceApiReponse(sources: FileSourceApiItem[]): FileSource[] {
  return sources.map(source => ({
    id: source.id,
    name: source.name,
    createdAt: moment(source.created_at),
    updatedAt: moment(source.updated_at),
    codec: source.codec,
    duration: source.duration,
    filesize: source.filesize,
  }));
}

export interface FormFileSource {
  name: string;
  file: File;
}
