import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from '../../../services/clipboard.service';
import { FormFieldComponent } from '../form-field/form-field.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'rkb-input-copy',
  standalone: true,
  imports: [CommonModule, FormFieldComponent, SvgIconComponent],
  templateUrl: './input-copy.component.html',
  styleUrl: './input-copy.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCopyComponent {
  constructor(
    private readonly clipboard: ClipboardService,
    private readonly translate: TranslateService,
  ) {}

  private _snackBar = inject(MatSnackBar);

  @Input()
  value: string;

  @HostBinding('class.-mb-6') // Useful is componennt is not inside form field
  @Input()
  removeMB = true;

  copyValue() {
    this.clipboard.copyMessage(this.value);
    this._snackBar.open(this.translate.instant('GLOBAL.COPY_CLIPBOARD'), null, {
      duration: 3000,
    });
  }
}
