import { Brand } from '../models/brand.model';
import { Encoding } from '../models/encoding.model';

const tag = '[Brand]';

export class FetchBrandRequest {
  static readonly type = `${tag} fetch brand request`;
  constructor(public brandId: string) {}
}

export class FetchBrandSuccess {
  static readonly type = `${tag} fetch brand success`;
  constructor(public brand: Brand) {}
}

export class FetchBrandError {
  static readonly type = `${tag} fetch brand error`;
  constructor(public error: any) {}
}
export class FetchBrandEncodingsRequest {
  static readonly type = `${tag} fetch brand encodings request`;
  constructor(public brandId: string) {}
}

export class FetchBrandEncodingsSuccess {
  static readonly type = `${tag} fetch brand encodings success`;
  constructor(public encodings: Encoding[]) {}
}

export class FetchBrandEncodingsError {
  static readonly type = `${tag} fetch brand encodings error`;
  constructor(public error: any) {}
}

export class PostBrandEncodingRequest {
  static readonly type = `${tag} post brand encoding request`;
  constructor(public encoding: Partial<Encoding>) {}
}

export class PostBrandEncodingSuccess {
  static readonly type = `${tag} post brand encoding success`;
  constructor(public newEncodings: Encoding[]) {}
}

export class PostBrandEncodingError {
  static readonly type = `${tag} post brand encoding error`;
  constructor(public error: any) {}
}

export class SetCurrentBrandId {
  static readonly type = `${tag} set current brand id`;
  constructor(public brandId: string) {}
}

export class UpdateBrandRequest {
  static readonly type = `${tag} update brand request`;
  constructor(public brand: Partial<Brand>) {}
}

export class UpdateBrandSuccess {
  static readonly type = `${tag} update brand success`;
  constructor(public brand: Brand) {}
}

export class UpdateBrandError {
  static readonly type = `${tag} update brand error`;
  constructor(public error: any) {}
}

export class FetchTagsRequest {
  static readonly type = `${tag} fetch tags request`;
  constructor() {}
}

export class FetchTagsSuccess {
  static readonly type = `${tag} fetch tags success`;
  constructor(public tags: string[]) {}
}

export class FetchTagsError {
  static readonly type = `${tag} fetch tags error`;
  constructor(public error: any) {}
}
