import {
  FileSource,
  FormFileSource,
  FormStreamSource,
  StreamSource,
} from '../models/source.model';

const tag = '[Source]';

export class FetchBrandSourcesRequest {
  static readonly type = `${tag} fetch brand sources request`;
  constructor() {}
}

export class FetchBrandSourcesSuccess {
  static readonly type = `${tag} fetch brand sources success`;
  constructor(
    public streamSources: StreamSource[],
    public fileSources: FileSource[],
  ) {}
}

export class FetchBrandSourcesError {
  static readonly type = `${tag} fetch brand sources error`;
  constructor(public error: any) {}
}

export class PostBrandStreamSourceRequest {
  static readonly type = `${tag} post brand stream source request`;
  constructor(
    public source: FormStreamSource,
    public failover: FormStreamSource,
  ) {}
}

export class PostBrandStreamSourceSuccess {
  static readonly type = `${tag} post brand stream source success`;
  constructor(public newSources: StreamSource[]) {}
}

export class PostBrandStreamSourceError {
  static readonly type = `${tag} post brand stream source error`;
  constructor(public error: any) {}
}

export class SetCurrentStreamSource {
  static readonly type = `${tag} set current stream source`;
  constructor(public source: StreamSource) {}
}

export class PostBrandFileSourceRequest {
  static readonly type = `${tag} post brand file source request`;
  constructor(public source: FormFileSource) {}
}

export class PostBrandFileSourceSuccess {
  static readonly type = `${tag} post brand file source success`;
  constructor(public newSources: FileSource[]) {}
}

export class PostBrandFileSourceError {
  static readonly type = `${tag} post brand file source error`;
  constructor(public error: any) {}
}

export class SetCurrentFileSource {
  static readonly type = `${tag} set current file source`;
  constructor(public source: FileSource) {}
}

export class ResetNewSourceId {
  static readonly type = `${tag} reset new source id`;
  constructor() {}
}

export class RegenerateSecretKeyRequest {
  static readonly type = `${tag} regenerate secret key request`;
  constructor(public source: StreamSource) {}
}

export class RegenerateSecretKeySuccess {
  static readonly type = `${tag} regenerate secret key success`;
  constructor(public source: StreamSource) {}
}

export class RegenerateSecretKeyError {
  static readonly type = `${tag} regenerate secret key error`;
  constructor(public error: any) {}
}
