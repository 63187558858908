<div class="chip">
  <span class="chip-label">{{ label }}</span>
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="chip-remove"
    (click)="onRemove()"
  >
    <g id="Group 50">
      <path
        id="Vector"
        d="M8 1.6665L1 8.6665"
        stroke="#fbbf9f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M1 1.6665L8 8  .6665"
        stroke="#fbbf9f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</div>
