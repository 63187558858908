import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { OrgState } from '../../org/states/org.state';
import { Brand } from '../models/brand.model';
import {
  convertEncodingApiResponse,
  Encoding,
  EncodingApiItem,
} from '../models/encoding.model';
import { BrandService } from '../services/brand.service';
import {
  FetchBrandEncodingsError,
  FetchBrandEncodingsRequest,
  FetchBrandEncodingsSuccess,
  FetchBrandError,
  FetchBrandRequest,
  FetchBrandSuccess,
  FetchTagsError,
  FetchTagsRequest,
  FetchTagsSuccess,
  PostBrandEncodingError,
  PostBrandEncodingRequest,
  PostBrandEncodingSuccess,
  SetCurrentBrandId,
  UpdateBrandError,
  UpdateBrandRequest,
  UpdateBrandSuccess,
} from './brand.actions';

export class BrandStateModel {
  encodings: Encoding[];
  currentBrandId: string;
  currentBrand: Brand;
  isLoading: boolean;
  tags: string[];
}

@State<BrandStateModel>({
  name: 'Brand',
  defaults: {
    encodings: [],
    tags: [],
    currentBrandId: null,
    currentBrand: null,
    isLoading: false,
  },
})
@Injectable()
export class BrandState {
  constructor(
    private readonly store: Store,
    private readonly brandService: BrandService,
  ) {}

  @Selector()
  static brandId(state: BrandStateModel): string {
    return state.currentBrandId;
  }

  @Selector()
  static currentBrand(state: BrandStateModel): Brand {
    return state.currentBrand;
  }

  @Selector()
  static tags(state: BrandStateModel): string[] {
    return state.tags;
  }

  @Selector()
  static encodings(state: BrandStateModel): Encoding[] {
    return state.encodings;
  }

  @Action(SetCurrentBrandId)
  setCurrentBrandId(ctx: StateContext<BrandStateModel>, { brandId }: SetCurrentBrandId) {
    return ctx.patchState({
      currentBrandId: brandId,
    });
  }

  @Action(FetchBrandRequest)
  fetchBrandRequest(
    ctx: StateContext<BrandStateModel>,
    { brandId }: FetchBrandEncodingsRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.brandService
      .getBrand(this.store.selectSnapshot(OrgState.orgId), brandId)
      .pipe(
        tap((brand: Brand) => {
          ctx.dispatch(new FetchBrandSuccess(brand));
        }),
        catchError(error => ctx.dispatch(new FetchBrandError(error))),
      );
  }

  @Action(FetchBrandSuccess)
  fetchBrandSuccess(ctx: StateContext<BrandStateModel>, { brand }: FetchBrandSuccess) {
    ctx.patchState({
      isLoading: false,
      currentBrand: brand,
    });
  }

  @Action(FetchBrandEncodingsRequest)
  fetchBrandEncodingsRequest(
    ctx: StateContext<BrandStateModel>,
    { brandId }: FetchBrandEncodingsRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.brandService.getBrandEncodings(brandId).pipe(
      tap((apiResponse: EncodingApiItem[]) => {
        ctx.dispatch(
          new FetchBrandEncodingsSuccess(convertEncodingApiResponse(apiResponse)),
        );
      }),
      catchError(error => ctx.dispatch(new FetchBrandEncodingsError(error))),
    );
  }

  @Action(FetchBrandEncodingsSuccess)
  fetchBrandEncodingsSuccess(
    ctx: StateContext<BrandStateModel>,
    { encodings }: FetchBrandEncodingsSuccess,
  ) {
    ctx.patchState({
      encodings,
      isLoading: false,
    });
  }

  @Action([FetchBrandEncodingsSuccess, FetchBrandEncodingsError])
  fetchBrandEncodingsComplete(ctx: StateContext<BrandStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(PostBrandEncodingRequest)
  postBrandEncodingRequest(
    ctx: StateContext<BrandStateModel>,
    { encoding }: PostBrandEncodingRequest,
  ) {
    ctx.patchState({ isLoading: true });
    const brandId = ctx.getState().currentBrandId;

    return this.brandService.createBrandEncoding(brandId, encoding).pipe(
      tap((apiResponse: EncodingApiItem) => {
        ctx.dispatch(new FetchBrandEncodingsRequest(brandId));
      }),
      catchError(error => {
        ctx.dispatch(new PostBrandEncodingError(error));
        throw error;
      }),
    );
  }

  @Action(PostBrandEncodingSuccess)
  postBrandEncodingSuccess(
    ctx: StateContext<BrandStateModel>,
    { newEncodings }: PostBrandEncodingSuccess,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      encodings: newEncodings,
      isLoading: false,
    });
  }

  @Action(UpdateBrandRequest)
  updateBrandRequest(ctx: StateContext<BrandStateModel>, { brand }: UpdateBrandRequest) {
    ctx.patchState({ isLoading: true });

    const orgId = this.store.selectSnapshot(OrgState.orgId);
    const brandId = ctx.getState().currentBrandId;

    return this.brandService.updateBrand(orgId, brandId, brand).pipe(
      tap((updatedBrand: Brand) => {
        ctx.dispatch(new UpdateBrandSuccess(updatedBrand));
      }),
      catchError(error => ctx.dispatch(new UpdateBrandError(error))),
    );
  }

  @Action(UpdateBrandSuccess)
  updateBrandSuccess(ctx: StateContext<BrandStateModel>, { brand }: UpdateBrandSuccess) {
    ctx.patchState({
      currentBrand: brand,
      isLoading: false,
    });
  }

  @Action(UpdateBrandError)
  updateBrandError(ctx: StateContext<BrandStateModel>, { error }: UpdateBrandError) {
    ctx.patchState({
      isLoading: false,
    });
    console.error('Update brand error', error);
  }

  @Action(FetchTagsRequest)
  fetchTagsRequest(ctx: StateContext<BrandStateModel>) {
    ctx.patchState({ isLoading: true });

    const brandId = ctx.getState().currentBrandId;

    return this.brandService.getTags(brandId).pipe(
      tap((tags: string[]) => {
        ctx.dispatch(new FetchTagsSuccess(tags));
      }),
      catchError(error => ctx.dispatch(new FetchTagsError(error))),
    );
  }

  @Action(FetchTagsSuccess)
  fetchTagsSuccess(ctx: StateContext<BrandStateModel>, { tags }: FetchTagsSuccess) {
    ctx.patchState({
      tags,
      isLoading: false,
    });
  }

  @Action(FetchTagsError)
  fetchTagsError(ctx: StateContext<BrandStateModel>, { error }: FetchTagsError) {
    ctx.patchState({ isLoading: false });
    console.error('Fetch tags error', error);
  }
}
