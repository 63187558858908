import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rkb-chip',
  standalone: true,
  imports: [],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {
  @Input() label: string;

  @Output() remove = new EventEmitter<void>();

  onRemove() {
    this.remove.emit();
  }
}
