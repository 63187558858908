import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CloseDrawer } from '@rkb/shell/states/drawer.actions';
import { catchError, mergeMap, of } from 'rxjs';
import { FileSource, StreamSource } from '../models/source.model';
import { BrandService } from '../services/brand.service';
import { BrandState } from './brand.state';
import {
  FetchBrandSourcesError,
  FetchBrandSourcesRequest,
  FetchBrandSourcesSuccess,
  PostBrandStreamSourceError,
  PostBrandStreamSourceRequest,
  PostBrandStreamSourceSuccess,
  RegenerateSecretKeyError,
  RegenerateSecretKeyRequest,
  RegenerateSecretKeySuccess,
  ResetNewSourceId,
  SetCurrentStreamSource,
} from './source.actions';

export class SourceStateModel {
  streamSources: StreamSource[];
  fileSources: FileSource[];
  currentSource: StreamSource;
  isLoading: boolean;
  newSourceId: string;
}

@State<SourceStateModel>({
  name: 'Source',
  defaults: {
    streamSources: [],
    fileSources: [],
    currentSource: null,
    isLoading: false,
    newSourceId: null,
  },
})
@Injectable()
export class SourceState {
  constructor(
    private readonly store: Store,
    private readonly brandService: BrandService,
  ) {}

  @Selector()
  static streamSources(state: SourceStateModel): StreamSource[] {
    return state.streamSources;
  }

  @Selector()
  static fileSources(state: SourceStateModel): FileSource[] {
    return state.fileSources;
  }

  @Selector()
  static newSourceId(state: SourceStateModel): string {
    return state.newSourceId;
  }

  @Selector()
  static currentSource(state: SourceStateModel): StreamSource {
    return state.currentSource;
  }

  @Action(FetchBrandSourcesRequest)
  fetchBrandSources(ctx: StateContext<SourceStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    return this.brandService
      .getBrandSources(this.store.selectSnapshot(BrandState.brandId))
      .pipe(
        mergeMap(sources =>
          ctx.dispatch(new FetchBrandSourcesSuccess(sources.streams, sources.files)),
        ),
        catchError(err => ctx.dispatch(new FetchBrandSourcesError(err))),
      );
  }

  @Action(FetchBrandSourcesSuccess)
  fetchBrandSourcesSuccess(
    ctx: StateContext<SourceStateModel>,
    { streamSources, fileSources }: FetchBrandSourcesSuccess,
  ) {
    ctx.patchState({
      streamSources,
      fileSources,
      isLoading: false,
    });
  }

  @Action(PostBrandStreamSourceRequest)
  postBrandStreamSource(
    ctx: StateContext<SourceStateModel>,
    { source, failover }: PostBrandStreamSourceRequest,
  ) {
    ctx.patchState({ isLoading: true });
    return this.brandService
      .postBrandStreamSource(this.store.selectSnapshot(BrandState.brandId), source)
      .pipe(
        mergeMap(([newSource]) => {
          if (failover) {
            return this.brandService.postBrandStreamSource(
              this.store.selectSnapshot(BrandState.brandId),
              { ...failover, parentID: newSource.id },
              newSource,
            );
          }
          return of([newSource]);
        }),
        mergeMap((sources: StreamSource[]) => {
          return ctx.dispatch(new PostBrandStreamSourceSuccess(sources));
        }),
        catchError(err => ctx.dispatch(new PostBrandStreamSourceError(err))),
      );
  }

  @Action(PostBrandStreamSourceSuccess)
  postBrandStreamSourceSuccess(
    ctx: StateContext<SourceStateModel>,
    { newSources }: PostBrandStreamSourceSuccess,
  ) {
    ctx.patchState({ newSourceId: newSources[0].id });
    return ctx.dispatch([new FetchBrandSourcesRequest(), new CloseDrawer()]);
  }

  @Action(SetCurrentStreamSource)
  setCurrentStreamource(
    ctx: StateContext<SourceStateModel>,
    { source }: SetCurrentStreamSource,
  ) {
    ctx.patchState({ currentSource: source });
  }

  @Action(ResetNewSourceId)
  resetNewSourceId(ctx: StateContext<SourceStateModel>) {
    ctx.patchState({ newSourceId: null });
  }

  @Action(RegenerateSecretKeyRequest)
  regenerateSecretKey(
    ctx: StateContext<SourceStateModel>,
    { source }: RegenerateSecretKeyRequest,
  ) {
    return this.brandService
      .regenerateSecretKey(this.store.selectSnapshot(BrandState.brandId), source)
      .pipe(
        mergeMap(source => ctx.dispatch(new RegenerateSecretKeySuccess(source))),
        catchError(err => ctx.dispatch(new RegenerateSecretKeyError(err))),
      );
  }
}
